$FONT_PRIMARY: Roboto, sans-serif !important;
$FONT_SECONDARY: Dancing Script, cursive !important;
$HEADER_FONT_PRIMARY: Roboto, sans-serif !important;
$base-font-size: 16; // Base font-size in pixels.

$screen-sm-max: 600px;
$screen-md-min: 600px;
$screen-md-max: 900px;
$screen-lg-min: 900px;

@use "sass:math";
@use "colors" as c;

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

@mixin font-setting($font-family, $font-size, $font-weight, $letter-spacing, $line-height, $color) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  color: $color;
}

.headline1 {
  @include font-setting($FONT_PRIMARY, pxtorem(24), 500, pxtorem(0), 130%, c.$dark-grey);
}

.headline2 {
  @include font-setting($FONT_PRIMARY, pxtorem(18), 500, pxtorem(0), 130%, c.$dark-grey);
}

.headline3 {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 500, pxtorem(0), 150%, c.$dark-grey);
}

.headline4 {
  @include font-setting($FONT_PRIMARY, pxtorem(12), 500, pxtorem(0), 130%, c.$dark-grey);
}

.bodyLarge {
  @include font-setting($FONT_PRIMARY, pxtorem(16), 400, pxtorem(0), 150%, c.$dark-grey);
}

.paragraph {
  @include font-setting($FONT_PRIMARY, pxtorem(14), 400, pxtorem(0), 150%, c.$dark-grey);
}

.paragraphSmall {
  @include font-setting($FONT_PRIMARY, pxtorem(12), 400, pxtorem(0), 130%, c.$dark-grey);
}

// custom avatar fonts

.avatar100 {
  @include font-setting($FONT_PRIMARY, pxtorem(24), 400, pxtorem(0), 150%, c.$dark-grey);
}

.avatar40 {
  @include font-setting($FONT_PRIMARY, pxtorem(18), 400, pxtorem(0), 150%, c.$dark-grey);
}

.avatar20 {
  @include font-setting($FONT_PRIMARY, pxtorem(10), 400, pxtorem(0), 130%, c.$dark-grey);
}

.avatar16 {
  @include font-setting($FONT_PRIMARY, pxtorem(8), 400, pxtorem(0), 130%, c.$dark-grey);
}

.avatar14 {
  @include font-setting($FONT_PRIMARY, pxtorem(6), 400, pxtorem(0), 130%, c.$dark-grey);
}
