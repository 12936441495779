.view-item-box {
  height: 55px;
}

.view-item-padding {
  padding: 10px;
  border-radius: 10px;
}

.view-item-padding:hover {
  pointer-events: auto;
  cursor: pointer;
  background-color: #f6f3ef;
}

.view-item-padding:focus {
  pointer-events: auto;
  cursor: pointer;
  background-color: #eee9e3;
}

.view-item-padding-active {
  padding: 10px;
  pointer-events: auto;
  cursor: pointer;
  background-color: #f6f3ef;
  border-radius: 10px;
}

.view-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-item-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 0;
}

.item-title-detail {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loader-padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
