.apexcharts-xaxis-label-small-12 {
  font-family: 'Roboto, sans-serif !important';
  font-size: 12;
  font-weight: 400;
  letter-spacing: '-0.3px !important';
  line-height: '16px';
  color: white !important;
}

.arrow_box {
  background-color: black;
  padding: 5px 10px;
  font-family: 'Roboto, sans-serif !important';
  font-size: 12;
  font-weight: 400;
  letter-spacing: '-0.3px !important';
  line-height: '16px';
  color: white !important;
  border-radius: 5px;
}

.arrow_box_text {
  display: block;
  background-color: black;
  padding: 5px 10px;
  font-family: 'Roboto, sans-serif !important';
  font-size: 12;
  font-weight: 400;
  letter-spacing: '-0.3px !important';
  line-height: '16px';
  color: white !important;
  border-radius: 5px;
}

.apexcharts-tooltip {
  border: 1px solid black !important;
  background-color: black !important;
  color: white !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  display: flex;
  align-items: flex-start !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: black !important;
  background: black !important;
  border: 1px solid black !important;
  color: white !important;
}
