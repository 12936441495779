$base-font-size: 16; // Base font-size in pixels.

@use "sass:math";
@use "../abstract/colors.scss" as c;

@function pxtorem($px) {
  @return #{math.div($px, $base-font-size)}rem;
}

.circle-icon-button {
  cursor: pointer;
  height: pxtorem(36);
  width: pxtorem(36);
  padding: pxtorem(10);
  border-radius: pxtorem(18);
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medium-icon-button {
  cursor: pointer;
  height: pxtorem(40);
  width: pxtorem(40);
  padding: pxtorem(10);
  border-radius: pxtorem(10);
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-icon-button {
  min-width: max-content;
  cursor: pointer;
  height: pxtorem(30);
  width: pxtorem(30);
  padding: pxtorem(8);
  border-radius: pxtorem(8);
  box-shadow: none;
  text-transform: inherit;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-icon-button {
  background-color: c.$yellow-zelt;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-icon-button:hover {
  background-color: c.$yellow-hover;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-icon-button:target {
  background-color: c.$yellow-press;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.primary-icon-button:disabled {
  opacity: 50%;
}

.secondary-icon-button {
  background-color: c.$background;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-icon-button:hover {
  background-color: c.$grey-hover;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-icon-button:target {
  background-color: c.$grey-press;
  color: c.$dark-grey;
  fill: c.$dark-grey;
}

.secondary-icon-button:disabled {
  opacity: 50%;
}

.danger-icon-button {
  background-color: c.$red-dark;
  color: c.$white;
  fill: c.$white;
}

.danger-icon-button:hover {
  background-color: c.$red-hover;
  color: c.$white;
  fill: c.$white;
}

.danger-icon-button:target {
  background-color: c.$red-dark;
  color: c.$white;
  fill: c.$white;
}

.danger-icon-button:disabled {
  opacity: 50%;
}

.light-icon-button {
  background-color: transparent;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-icon-button:not(:disabled):hover {
  background-color: c.$grey-hover;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-icon-button:target {
  background-color: c.$grey-press;
  fill: c.$dark-grey;
  color: c.$dark-grey;
}

.light-icon-button:disabled {
  cursor: default;
  opacity: 50%;
}
